/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "../css/localizacao.css";
import {
  TextField,
  Button,
  createMuiTheme,
  FormControlLabel,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
});

const CheckboxColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$checked": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RadioColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$selected": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function getSteps() {
  return ["Localização"];
}

const PersonalForm = ({
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao = null,
  validateOnChange = true,
}) => {
  const [maxLength, setMaxLength] = useState(20);

  const cuiMaxLength = (value) => {
    setMaxLength(value);
  };

  const handleChange = async (e) => {
    e.preventDefault();
    let texto = e.target.value;
    if (e.target.name === "cod_postal_forn") {
      texto = texto.replace(/\s+/g, "");
      texto = texto.replace(/(\d{4})(?=\d)/g, "$1-");
    }
    if (e.target.name === "cod_postal_forn" && e.target.value.length === 8) {
      // Perform request after full postal code is entered
      try {
        const response = await fetch(
          `https://jafplus.api.adesao.jaf.com.pt/api/codPostal/${texto}`
        );
        const data = await response.json();
        setLocalidade((prevState) => ({
          ...prevState,
          localidade_forn: data.localidade,
          concelho_forn: data.concelho,
          distrito_forn: data.distrito,
        }));

        // Handle the API response (you can update other state variables based on this)
        console.log("API Response: ", data);
      } catch (error) {
        console.error("API request failed: ", error);
      }
    }
    if (e.target.name === "cui") {
      if (texto.includes(" ")) {
        if (texto.split(" ").length == 1) {
          // console.log('Yes, it has 1 space');
          cuiMaxLength(20);
        } else if (texto.split(" ").length == 2) {
          // console.log('Yes, it has 2 space');
          cuiMaxLength(21);
        } else if (texto.split(" ").length == 3) {
          // console.log('Yes, it has 3 space');
          cuiMaxLength(22);
        } else if (texto.split(" ").length == 4) {
          // console.log('Yes, it has 4 space');
          cuiMaxLength(23);
        } else if (texto.split(" ").length == 5) {
          // console.log('Yes, it has 5 space');
          cuiMaxLength(24);
        } else if (texto.split(" ").length == 6) {
          // console.log('Yes, it has 6 space');
          cuiMaxLength(25);
        }
      } else {
        cuiMaxLength(20);
      }
    }

    setLocalidade((prevState) => ({
      ...prevState,
      [e.target.name]: texto,
    }));

    if (validateOnChange)
      validationLocalizacao({ [e.target.name]: e.target.value });
  };

  const handleChangeRadioFornecimento = (event) => {
    setLocalidade({
      ...localidade,
      fornecimento_gas: event.target.value,
    });
    if (validateOnChange)
      validationLocalizacao({ [event.target.name]: event.target.value });
  };

  const handleChangeRadioInspecao = (event) => {
    setLocalidade({
      ...localidade,
      inspecao_gas: event.target.value,
    });
    if (validateOnChange)
      validationLocalizacao({ [event.target.name]: event.target.value });
  };

  const handleChangeMultiponto = (e) => {
    setLocalidade({
      ...localidade,
      multiponto: !localidade.multiponto,
    });
  };

  const handleChangeRadioTipoCliente = (event) => {
    setLocalidade({
      ...localidade,
      tipo_cliente: event.target.value,
    });
    if (validateOnChange)
      validationLocalizacao({ [event.target.name]: event.target.value });
  };

  const handleChangeRadioTipoPredio = (event) => {
    setLocalidade({
      ...localidade,
      tipo_predio: event.target.value,
    });
    if (validateOnChange)
      validationLocalizacao({ [event.target.name]: event.target.value });
  };

  const handleChangeMesmaMorada = (e) => {
    setLocalidade({
      ...localidade,
      mesma_morada_local_forn: !localidade.mesma_morada_local_forn,
    });
  };

  // const handleChangeDeclaracao = (e) => {
  //   setLocalidade({
  //     ...localidade,
  //     declaracao: !localidade.declaracao,
  //   });
  // };

  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div className="titulo" style={{ marginTop: 10 }}>
          Morada e dados do local de fornecimento
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FormGroup
            row
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <div>
              <FormControlLabel
                control={
                  <CheckboxColored
                    checked={localidade.mesma_morada_local_forn}
                    onChange={handleChangeMesmaMorada}
                    name="mesma_morada_local_forn"
                  />
                }
                label="Mesma morada"
              />
            </div>
          </FormGroup>
          {localidade.mesma_morada_local_forn == false && (
            <TextField
              id="morada_forn"
              name="morada_forn"
              label="Morada *"
              value={localidade.morada_forn}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              // className="textfield"
              style={{ marginBottom: 8, width: "48%" }}
              {...(errorsLocalizacao.morada_forn && {
                error: true,
                helperText: errorsLocalizacao.morada_forn,
              })}
            />
          )}
        </div>
        {localidade.mesma_morada_local_forn == false && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              id="cod_postal_forn"
              name="cod_postal_forn"
              label="Código Postal *"
              // className="cod_postal"
              value={localidade.cod_postal_forn}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              inputProps={{ maxLength: 8 }}
              style={{ marginBottom: 8, width: "16%" }}
              {...(errorsLocalizacao.cod_postal_forn && {
                error: true,
                helperText: errorsLocalizacao.cod_postal_forn,
              })}
            />
            <TextField
              id="localidade_forn"
              name="localidade_forn"
              label="Localidade *"
              value={localidade.localidade_forn}
              // className="localidade"
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 8, width: "28%" }}
              {...(errorsLocalizacao.localidade_forn && {
                error: true,
                helperText: errorsLocalizacao.localidade_forn,
              })}
            />
            <TextField
              id="concelho_forn"
              name="concelho_forn"
              label="Concelho *"
              value={localidade.concelho_forn}
              // className="concelho_forn"
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 8, width: "22%" }}
              {...(errorsLocalizacao.concelho_forn && {
                error: true,
                helperText: errorsLocalizacao.concelho_forn,
              })}
            />
            <TextField
              id="distrito_forn"
              name="distrito_forn"
              label="Distrito *"
              value={localidade.distrito_forn}
              // className="distrito"
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 8, width: "22%" }}
              {...(errorsLocalizacao.distrito_forn && {
                error: true,
                helperText: errorsLocalizacao.distrito_forn,
              })}
            />
          </div>
        )}
        <TextField
          id="cui"
          name="cui"
          label="CUI *"
          // className="cpe"
          value={localidade.cui}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          style={{ marginBottom: 8 }}
          inputProps={{ maxLength: maxLength }}
          {...(errorsLocalizacao.cui && {
            error: true,
            helperText: errorsLocalizacao.cui,
          })}
        />
        <div style={{ marginTop: 10 }}>
          <Typography variant="p" style={{ fontSize: 10 }}>
            ** Pode consultar o CUI na sua fatura, junto dos dados do cliente,
            ou com a distribuidora de gás da sua região
          </Typography>
        </div>
        {/* Nova Ligação */}
        {/* <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={localidade.nova_ligacao}
                  onChange={handleChangeNovaLigacao}
                  name="nova_ligacao"
                />
              }
              label="Esta é uma nova ligação"
            />
          </div>
        </FormGroup>
        {/* Fornecimento de energia */}
        {/* <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={localidade.fornecimento_energia}
                  onChange={handleChangeFornecimentoEnergia}
                  name="fornecimento_energia"
                />
              }
              label="Este local tem fornecimento de energia"
            />
          </div>
        </FormGroup> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }}>
            <FormControl style={{ marginTop: 20 }}>
              <FormLabel id="fornecimento_gas">
                O local tem atualmente fornecimento de gás?
              </FormLabel>
              <RadioGroup row name="position">
                <FormControlLabel
                  value="yes"
                  control={
                    <RadioColored
                      checked={localidade.fornecimento_gas === "yes"}
                      onChange={handleChangeRadioFornecimento}
                      name="fornecimento_gas"
                    />
                  }
                  label="Sim"
                />
                <FormControlLabel
                  value="no"
                  control={
                    <RadioColored
                      checked={localidade.fornecimento_gas === "no"}
                      onChange={handleChangeRadioFornecimento}
                      name="fornecimento_gas"
                    />
                  }
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
            <div>
              {errorsLocalizacao.fornecimento_gas && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {errorsLocalizacao.fornecimento_gas}
                </FormHelperText>
              )}
            </div>

            {localidade.fornecimento_gas === "no" && (
              <div>
                <Typography>
                  Relembramos que será necessário:
                  <ul style={{ marginLeft: 20 }}>
                    <li>
                      enviar-nos um comprovatico em como pode contratar energia
                      nesta morada;
                    </li>
                    <li>
                      efectuar uma inspeção à sua instalação de gás, que terá um
                      custo de 60€ (IVA incluído)
                    </li>
                  </ul>
                </Typography>
              </div>
            )}
          </div>
          <div style={{ width: "48%" }}>
            <FormControl style={{ marginTop: 20 }}>
              <FormLabel id="inspecao_gas">
                Este local tem inspeção de gás?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <RadioColored
                      checked={localidade.inspecao_gas === "yes"}
                      onChange={handleChangeRadioInspecao}
                      name="inspecao_gas"
                    />
                  }
                  label="Sim"
                />
                <FormControlLabel
                  value="no"
                  control={
                    <RadioColored
                      checked={localidade.inspecao_gas === "no"}
                      onChange={handleChangeRadioInspecao}
                      name="inspecao_gas"
                    />
                  }
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
            <div>
              {errorsLocalizacao.inspecao_gas && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {errorsLocalizacao.inspecao_gas}
                </FormHelperText>
              )}
            </div>
            {localidade.inspecao_gas === "no" && (
              <div>
                <Typography>
                  Relembramos que será necessário:
                  <ul style={{ marginLeft: 20 }}>
                    <li>
                      enviar-nos um comprovatico em como pode contratar energia
                      nesta morada;
                    </li>
                    <li>
                      efectuar uma inspeção à sua instalação de gás, que terá um
                      custo de 60€ (IVA incluído)
                    </li>
                  </ul>
                </Typography>
              </div>
            )}
          </div>
        </div>
        <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={localidade.multiponto}
                  onChange={handleChangeMultiponto}
                  name="multiponto"
                />
              }
              label="Multiponto"
            />
          </div>
        </FormGroup>
        <div className="titulo" style={{ marginTop: 20 }}>
          Modelo de declaração de contrato de fornecimento
        </div>
        <FormControl
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            flexDirection: "row", // Align label and RadioGroup horizontally
            alignItems: "center", // Vertically center label and radio buttons
          }}
          error={!!errorsLocalizacao.tipo_cliente} // Show error if it
        >
          <FormLabel
            id="tipo_cliente"
            style={{ marginRight: 20, width: 100 }} // Add space between the label and radio buttons
          >
            Tipo Cliente:
          </FormLabel>

          <RadioGroup
            row
            aria-labelledby="tipo_cliente" // Keep the label reference for accessibility
            name="tipo_cliente"
          >
            <FormControlLabel
              value="1"
              control={
                <RadioColored
                  checked={localidade.tipo_cliente === "1"}
                  onChange={handleChangeRadioTipoCliente}
                  name="tipo_cliente"
                />
              }
              label="Proprietário"
            />
            <FormControlLabel
              value="2"
              control={
                <RadioColored
                  checked={localidade.tipo_cliente === "2"}
                  onChange={handleChangeRadioTipoCliente}
                  name="tipo_cliente"
                />
              }
              label="Arrendatário"
            />
            <FormControlLabel
              value="3"
              control={
                <RadioColored
                  checked={localidade.tipo_cliente === "3"}
                  onChange={handleChangeRadioTipoCliente}
                  name="tipo_cliente"
                />
              }
              label="Subarrendatário"
            />
            <FormControlLabel
              value="4"
              control={
                <RadioColored
                  checked={localidade.tipo_cliente === "4"}
                  onChange={handleChangeRadioTipoCliente}
                  name="tipo_cliente"
                />
              }
              label="Outro"
            />
          </RadioGroup>
          {errorsLocalizacao.tipo_cliente && (
            <FormHelperText>{errorsLocalizacao.tipo_cliente}</FormHelperText>
          )}
        </FormControl>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            style={{
              marginTop: 20,
              marginBottom: 20,
              display: "flex",
              flexDirection: "row", // Aligns children horizontally
              alignItems: "center", // Vertically center the label and radio buttons
            }}
          >
            <FormLabel
              id="tipo_predio"
              style={{ marginRight: 20, width: 100 }} // Add some space between label and radio buttons
            >
              Tipo de Prédio:
            </FormLabel>

            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="tipo_predio"
            >
              <FormControlLabel
                value="1"
                control={
                  <RadioColored
                    checked={localidade.tipo_predio === "1"}
                    onChange={handleChangeRadioTipoPredio}
                    name="tipo_predio"
                  />
                }
                label="Urbano"
              />
              <FormControlLabel
                value="2"
                control={
                  <RadioColored
                    checked={localidade.tipo_predio === "2"}
                    onChange={handleChangeRadioTipoPredio}
                    name="tipo_predio"
                  />
                }
                label="Rústico"
              />
              <FormControlLabel
                value="3"
                control={
                  <RadioColored
                    checked={localidade.tipo_predio === "3"}
                    onChange={handleChangeRadioTipoPredio}
                    name="tipo_predio"
                  />
                }
                label="Omisso"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="andar"
            name="andar"
            label="Fração/andar *"
            value={localidade.andar}
            className="andar"
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8, width: "25%" }}
            {...(errorsLocalizacao.andar && {
              error: true,
              helperText: errorsLocalizacao.andar,
            })}
          />
        </div>
      </ThemeProvider>
    </>
  );
};

function getStepContent(
  step,
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao
) {
  switch (step) {
    case 0:
      return (
        <PersonalForm
          localidade={localidade}
          setLocalidade={setLocalidade}
          validationLocalizacao={validationLocalizacao}
          errorsLocalizacao={errorsLocalizacao}
        />
      );

    default:
      return "unknown step";
  }
}

const Localizacao = ({
  value,
  methods,
  setValue,
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao,
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const handleNext = (data) => {
    if (validationLocalizacao()) {
      setActiveStep(activeStep + 1);
    } else return null;
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setValue(value - 1);
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      {activeStep === steps.length ? (
        setValue(value + 1)
      ) : (
        <>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            <Button
              className={classes.button}
              onClick={handleBack}
              style={{
                paddingRight: 10,
                borderRadius: "25px",
              }}
            >
              <ChevronLeft />
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                  marginTop: "1px",
                }}
              >
                voltar
              </span>
            </Button>
            <br />
            <br />
            {getStepContent(
              activeStep,
              localidade,
              setLocalidade,
              validationLocalizacao,
              errorsLocalizacao
            )}
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                paddingRight: 10,
                backgroundColor: "#05b6b7",
                borderRadius: "25px",
                color: "white",
              }}
              size="large"
            >
              Seguinte
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                }}
              >
                <ChevronRight />
              </span>
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default Localizacao;
