import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Simples = ({
  energia,
  setEnergia,
  errorsEnergia1 = null,
  validateOnChange = true,
  validationEnergia1,
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    setEnergia({
      ...energia,
      potencia: e.target.value,
    });
    if (validateOnChange) validationEnergia1({ potencia: e.target.value });
  };

  return (
    <div>
      <div className='titulo'>Selecione a potencia necessária</div>
      <FormControl
        variant='outlined'
        className={classes.formControl}
        {...(errorsEnergia1.potencia && { error: true })}
      >
        <InputLabel id='demo-simple-select-label'>Potência</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={energia.potencia}
          onChange={handleChange}
          label='Potência'
          variant='standard'
        >
          <MenuItem value={'1.15 kVA'}>1.15 kVA</MenuItem>
          <MenuItem value={'2.3 kVA'}>2.3 kVA</MenuItem>
          <MenuItem value={'3.45 kVA'}>3.45 kVA</MenuItem>
          <MenuItem value={'4.6 kVA'}>4.6 kVA</MenuItem>
          <MenuItem value={'5.75 kVA'}>5.75 kVA</MenuItem>
          <MenuItem value={'6.9 kVA'}>6.9 kVA</MenuItem>
          <MenuItem value={'10.35 kVA'}>10.35 kVA</MenuItem>
          <MenuItem value={'13.8 kVA'}>13.8 kVA</MenuItem>
          <MenuItem value={'17.25 kVA'}>17.25 kVA</MenuItem>
          <MenuItem value={'20.7 kVA'}>20.7 kVA</MenuItem>
        </Select>
        {errorsEnergia1.potencia && (
          <FormHelperText style={{ color: 'red' }}>
            {errorsEnergia1.potencia}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default Simples;
