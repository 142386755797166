import React from 'react';
import { Button } from '@material-ui/core';

const Simples = ({ energia, classes, handleButtonCiclo }) => {
  return (
    <div>
      <Button
        name='semCiclo'
        onClick={(e, value) => handleButtonCiclo(e, (value = 'semCiclo'))}
        className={
          energia.ciclo === 'semCiclo'
            ? classes.btnActiveTiny
            : classes.btnNormalTiny
        }
      >
        <div>S/Ciclo</div>
      </Button>
    </div>
  );
};

export default Simples;
