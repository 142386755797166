import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./css/app.css";
import Logo from "./css/images/img-icone-jafplus.png";
import TopNavEnergia from "./Navbars/TopNavEnergia";
import TopNavGas from "./Navbars/TopNavGas";
import { useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(window.location.search);
}

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    let contrato = query.get("contrato");
    if (!contrato || (contrato !== "eletricidade" && contrato !== "gas")) {
      contrato = "eletricidade";
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set("contrato", contrato);
      navigate(`${newUrl.pathname}?${newUrl.searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [location.search, navigate, query]);

  const contrato = query.get("contrato") || "eletricidade";

  const methods = useForm({
    defaultValues: {
      // Dados Pessoais
      nome: "",
      apelido: "",
      telemovel: "",
      email: "",
      nif: "",
      certidao_permanente: "",
      // aceito_condicoes_gerais: "",
      //Localização
      morada: "",
      cpe: "",
      cod_postal: "",
      localidade: "",
      //Plano Energético
      is_electricidade_gas: "",
      isElectricidade: "",
      isGas: "",
      isMaisEnergia: "",
      potencia: "",
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const dados = () => {
  //   JSON.stringify(methods.getValues());
  // };

  // useEffect(() => dados(), [dados, methods]);
  return (
    <div className="App">
      <div className="app-left">
        {" "}
        {contrato === "gas" ? (
          <TopNavGas methods={methods} />
        ) : contrato === "eletricidade" ? (
          <TopNavEnergia methods={methods} />
        ) : (
          <TopNavEnergia methods={methods} />
        )}
      </div>
      <div className="app-right">
        <div>
          <img src={Logo} alt="" className="logo-right" />
        </div>
        <div className="text-right">
          <h2>Somos a energia que o acompanha na sua casa ou na sua empresa</h2>
          <p>comercial@jafplus.pt</p>
          <p>apoiocliente@jafplus.pt</p>
        </div>
        <div className="text-right-footer">
          <p>Edifício SEDE Grupo JAF</p>
          <p>Rua 13 de Maio</p>
          <p>4800-530 Guimarães, Portugal</p>
          {/* <p>Rua 13 de Maio, nº1297</p>
          <p>4800-530</p> */}
        </div>
      </div>
    </div>
  );
};

export default App;
