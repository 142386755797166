/* eslint-disable eqeqeq */
import React, { useState } from "react";
import PropTypes from "prop-types";
import "../css/energia.css";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Box from "@material-ui/core/Box";
import "../css/topNav.css";
import logo from "../css/images/logo.png";

import DadosPessoais from "../dadosPessoaisGas/dadosPessoais";
import Energias from "../dadosPessoaisGas/energias";
import Localizacao from "../dadosPessoaisGas/localizacao";
import Documentos from "../dadosPessoaisGas/documentos";
import Resumo from "../dadosPessoaisGas/resumo";

//Funcionalidades para os dados pessoais
// Função que faz aparecer todo o conteúdo relacionado á TAB selecionada
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children} </>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const AntTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: "#05b6b7",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",

    minWidth: 72,

    marginRight: theme.spacing(0),
    "&:hover": {
      color: "#05b6b7",
      opacity: 1,
      transition: "0.2s",
    },
    "&$selected": {
      color: "#05b6b7",
      fontWeight: theme.typography.fontWeightMedium,
      transition: "0.2s",
    },
    "&:focus": {
      color: "#05b6b7",
      transition: "0.2s",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  topNavIndicatorColor: {
    backgroundColor: "#e8e8e8",
    color: "#e8e8e8",
  },
  topNavTextColor: {
    backgroundColor: "blue",
  },
}));

// Função que faz aparecer a TAB

const TopNavGas = ({ methods, validateOnChange = true }) => {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepResumo, setActiveStepResumo] = useState(0);
  const [disabledSteps, setDisabledSteps] = useState(false);

  // -------------------------- Validacao dos dados pessoais -----------------//
  const [errorsDados, setErrorsDados] = useState({});
  const [dados, setDados] = useState({
    checkbox_p_e: "particular",
    nome: "",
    apelido: "",
    nome_empresa: "",
    cod_certidao_permanente: "",
    nipc: "",
    cae: "",
    nome_rep_legal: "",
    morada: "",
    cod_postal: "",
    localidade: "",
    concelho: "",
    distrito: "",
    telefone: "",
    telemovel: "",
    email: "",
    nif: "",
    modalidade_pagamento: "",
    iban: "",
    swift: "",
    numero_documento: "",
    mesma_morada: "",
    morada_cor: "",
    cod_postal_cor: "",
    localidade_cor: "",
    concelho_cor: "",
    distrito_cor: "",
    validade_documento: null,
    certidao_permanente: "",
    cliente_prioridade: "",
    tarifa_social: false,
    emp_nome_ind: false,
    titular_contrato_anterior: false,
    preferencia_contacto: "",
    envio_fatura: "",
    aceito_condicoes_gerais: false,
  });
  const resetFormDados = () => {
    setDados({
      checkbox_p_e: "particular",
      nome: "",
      apelido: "",
      nome_empresa: "",
      cod_certidao_permanente: "",
      nipc: "",
      cae: "",
      nome_rep_legal: "",
      morada: "",
      cod_postal: "",
      localidade: "",
      concelho: "",
      distrito: "",
      telefone: "",
      telemovel: "",
      email: "",
      nif: "",
      modalidade_pagamento: "",
      iban: "",
      swift: "",
      numero_documento: "",
      mesma_morada: "",
      morada_cor: "",
      cod_postal_cor: "",
      localidade_cor: "",
      concelho_cor: "",
      distrito_cor: "",
      validade_documento: null,
      certidao_permanente: "",
      cliente_prioridade: "",
      tarifa_social: false,
      emp_nome_ind: false,
      titular_contrato_anterior: false,
      preferencia_contacto: "",
      envio_fatura: "",
      aceito_condicoes_gerais: false,
    });
    setErrorsDados({});
  };

  const validationDados = (fieldValues = dados) => {
    const temp = { ...errorsDados };

    if ("nome" in fieldValues && fieldValues.checkbox_p_e == "particular")
      temp.nome = fieldValues.nome ? "" : "Campo Obrigatório";
    if ("nome_empresa" in fieldValues && fieldValues.checkbox_p_e == "empresa")
      temp.nome_empresa = fieldValues.nome_empresa ? "" : "Campo Obrigatório";
    if ("morada" in fieldValues)
      temp.morada = fieldValues.morada ? "" : "Campo Obrigatório";
    if ("cod_postal" in fieldValues)
      temp.cod_postal = fieldValues.cod_postal ? "" : "Campo Obrigatório";
    // if ("localidade" in fieldValues)
    //   temp.localidade = fieldValues.localidade ? "" : "Campo Obrigatório";
    // if ("apelido" in fieldValues)
    //   temp.apelido = fieldValues.apelido ? "" : "Campo Obrigatório";
    if ("telefone" in fieldValues)
      temp.telefone =
        fieldValues.telefone.length === 9 ? "" : "Campo Obrigatório";
    if ("telemovel" in fieldValues)
      temp.telemovel =
        fieldValues.telemovel.length === 9 ? "" : "Campo Obrigatório";
    if ("email" in fieldValues)
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Campo Obrigatório';

    if ("nif" in fieldValues) {
      temp.nif = fieldValues.nif.length == 9 ? "" : "Campo Obrigatório";
      temp.certidao_permanente = "";
    }
    // if ("swift" in fieldValues) {
    //   temp.swift =
    //     fieldValues.swift.length >= 8 && fieldValues.swift.length <= 11
    //       ? ""
    //       : "Campo Obrigatório";
    // }
    // if ("iban" in fieldValues) {
    //   temp.iban = fieldValues.iban.length == 21 ? "" : "Campo Obrigatório";
    // }
    if ("numero_documento" in fieldValues) {
      temp.numero_documento =
        fieldValues.numero_documento.length < 9 &&
        fieldValues.numero_documento.length >= 1
          ? "O campo tem de ter pelo menos 9 caracteres"
          : fieldValues.numero_documento.length < 1
          ? "Campo obrigatório"
          : "";
    }
    // if ("validade_documento" in fieldValues)
    //   temp.validade_documento = fieldValues.validade_documento
    //     ? ""
    //     : "Campo Obrigatório";
    if ("certidao_permanente" in fieldValues && dados.nif.split("")[0] == 5) {
      temp.certidao_permanente =
        fieldValues.certidao_permanente.length == 14 ? "" : "Campo Obrigatório";
    }
    if ("preferencia_contacto" in fieldValues) {
      temp.preferencia_contacto = fieldValues.preferencia_contacto
        ? ""
        : "Campo Obrigatório";
    }
    if ("envio_fatura" in fieldValues) {
      temp.envio_fatura = fieldValues.envio_fatura ? "" : "Campo Obrigatório";
    }

    // if ("aceito_condicoes_gerais" in fieldValues) {
    //   temp.aceito_condicoes_gerais =
    //     fieldValues.aceito_condicoes_gerais == true
    //       ? ""
    //       : "Aceite os termos e condições";
    // }

    setErrorsDados({
      ...temp,
    });
    if (fieldValues == dados) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  // -------------------------- Validacao da localização -----------------//
  const [errorsLocalizacao, setErrorsLocalizacao] = useState({});
  const [localidade, setLocalidade] = useState({
    mesma_morada_local_forn: false,
    morada_forn: "",
    cod_postal_forn: "",
    localidade_forn: "",
    concelho_forn: "",
    distrito_forn: "",
    cui: "",
    fornecimento_gas: "",
    inspecao_gas: "",
    multiponto: false,
    tipo_cliente: "",
    tipo_predio: "",
    andar: "",
    declaracao: false,
  });

  const resetFormLocalizacao = () => {
    setLocalidade({
      mesma_morada_local_forn: false,
      morada_forn: "",
      cod_postal_forn: "",
      localidade_forn: "",
      concelho_forn: "",
      distrito_forn: "",
      cui: "",
      fornecimento_gas: "",
      inspecao_gas: "",
      multiponto: false,
      tipo_cliente: "",
      tipo_predio: "",
      andar: "",
      declaracao: false,
    });
    setErrorsDados({});
  };

  const validationLocalizacao = (fieldValues = localidade) => {
    const temp = { ...errorsLocalizacao };

    if ("morada" in fieldValues)
      temp.morada = fieldValues.morada ? "" : "Campo Obrigatório";
    if ("cui" in fieldValues) {
      let texto = fieldValues.cui.replace(/\s+/g, "");

      temp.cui = texto.length === 20 ? "" : "O CUI deve conter 20 carateres";
    }
    if ("cod_postal" in fieldValues)
      temp.cod_postal = fieldValues.cod_postal ? "" : "Campo Obrigatório";
    if ("localidade" in fieldValues)
      temp.localidade = fieldValues.localidade.length
        ? ""
        : "Campo Obrigatório";
    if ("fornecimento_gas" in fieldValues)
      temp.fornecimento_gas = fieldValues.fornecimento_gas
        ? ""
        : "Campo Obrigatório";
    if ("inspecao_gas" in fieldValues)
      temp.inspecao_gas = fieldValues.inspecao_gas ? "" : "Campo Obrigatório";
    if ("tipo_cliente" in fieldValues)
      temp.tipo_cliente = fieldValues.tipo_cliente.length
        ? ""
        : "Campo Obrigatório";

    setErrorsLocalizacao({
      ...temp,
    });
    if (fieldValues == localidade) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  // -------------------------- Validacao das Energias -----------------//
  const [errorsEnergia, setErrorsEnergia] = useState({});
  const [errorsEnergia1, setErrorsEnergia1] = useState({});
  const [errorsEnergia2, setErrorsEnergia2] = useState({});
  const [errorsEnergia3, setErrorsEnergia3] = useState({});
  const [validEnergias, setValidEnergias] = useState();
  const [validEnergias1, setValidEnergias1] = useState();
  const [validEnergias2, setValidEnergias2] = useState();
  const [validEnergias3, setValidEnergias3] = useState();
  const [energia, setEnergia] = useState({
    tipo1: "",
    is_electricidade_gas: false,
    isElectricidade: false,
    isGas: false,
    isMaisEnergia: false,
    // eletricidade
    tarifa: "",
    potencia: "",
    // gas
    escalao: "",

    //Ciclo
    ciclo: "",
  });

  const resetFormEnergia = () => {
    setEnergia({
      tipo1: "",
      is_electricidade_gas: false,
      isElectricidade: false,
      isGas: false,
      isMaisEnergia: false,
      // eletricidade
      tarifa: "",
      potencia: "",
      // gas
      escalao: "",

      //Ciclo
      ciclo: "",
      metodo: "",
    });
    setErrorsEnergia({});
    setErrorsEnergia1({});
    setErrorsEnergia2({});
    setErrorsEnergia3({});
  };

  console.log(energia.ciclo.length);

  const validationEnergia = (fieldValues = energia) => {
    const temp = { ...errorsEnergia };

    if ("tipo1" in fieldValues) {
      temp.tipo1 =
        fieldValues.tipo1.length != 0 ? "" : "Selecione um tipo de energia";
      setValidEnergias(true);
    }

    setErrorsEnergia({
      ...temp,
    });
    if (fieldValues == energia) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };
  const validationEnergia1 = (fieldValues = energia) => {
    const temp = { ...errorsEnergia1 };

    if ("tarifa" in fieldValues) {
      temp.tarifa =
        fieldValues.tarifa.length != 0 ? "" : "Selecione uma tarifa";
      setValidEnergias1(true);
    }
    if ("potencia" in fieldValues) {
      temp.potencia =
        fieldValues.potencia.length != 0 ? "" : "Selecione uma potência";
    }

    setErrorsEnergia1({
      ...temp,
    });
    if (fieldValues == energia) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };
  const validationEnergia2 = (fieldValues = energia) => {
    const temp = { ...errorsEnergia2 };

    if ("escalao" in fieldValues) {
      temp.escalao =
        fieldValues.escalao.length != 0 ? "" : "Selecione um escalão";
      setValidEnergias2(true);
    }
    setErrorsEnergia2({
      ...temp,
    });
    if (fieldValues == energia) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };
  const validationEnergia3 = (fieldValues = energia) => {
    const temp = { ...errorsEnergia3 };

    console.log(fieldValues);

    if ("ciclo" in fieldValues) {
      temp.ciclo = fieldValues.ciclo.length != 0 ? "" : "Selecione um ciclo";
      setValidEnergias3(true);
    }
    setErrorsEnergia3({
      ...temp,
    });
    if (fieldValues == energia) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  // -------------------------- Validacao de Documentos -----------------//
  const [errorsDocumentos, setErrorsDocumentos] = useState({});
  const [documentos, setDocumentos] = useState({
    faturaGas: "",
    comprovativoIban: "",
    outrosDocumentos: "",
    observacoes: "",
    autorizacao: false,
  });

  const resetFormDocumentos = () => {
    setDocumentos({
      faturaGas: "",
      comprovativoIban: "",
      outrosDocumentos: {},
      observacoes: "",
      autorizacao: false,
    });
  };

  const validationDocumentos = (fieldValues = documentos) => {
    const temp = { ...errorsDocumentos };
    console.log(fieldValues);

    if ("faturaGas" in fieldValues)
      temp.faturaGas = fieldValues.faturaGas ? "" : "Documento Obrigatório";
    // if ("comprovativoIban" in fieldValues) {
    //   temp.comprovativoIban = fieldValues.comprovativoIban
    //     ? ""
    //     : "Documento Obrigatório";
    // }
    if ("autorizacao" in fieldValues) {
      temp.autorizacao =
        fieldValues.autorizacao == true ? "" : "Aceite a autorização";
    }

    setErrorsDocumentos({
      ...temp,
    });
    if (fieldValues == documentos) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  const resumo = { ...dados, ...localidade, ...energia, ...documentos };

  const handleChangeTab = (event, newValue) => {
    if (value == 0) {
      if (validationDados()) {
        setValue(newValue);
      } else {
        setValue(0);
      }
    }
    if (value == 1) {
      if (newValue < value) {
        setValue(newValue);
      } else {
        if (validationLocalizacao()) {
          setValue(newValue);
        } else {
          setValue(1);
        }
      }
    }
    if (value == 2) {
      if (newValue < value) {
        setValue(newValue);
      } else {
        if (
          validationEnergia() &&
          validationEnergia1() &&
          validationEnergia3()
        ) {
          setValue(newValue);
        } else {
          setValue(2);
        }
      }
    }
    if (value == 3) {
      if (newValue < value) {
        setValue(newValue);
      } else {
        if (validationDocumentos()) {
          setValue(newValue);
        } else {
          setValue(3);
        }
      }
    }
    if (value == 4) {
      if (activeStep === 0) {
        setValue(newValue);
      } else {
        setValue(4);
      }
    }
  };

  return (
    <div>
      <div className={classes.root} style={{ display: "block" }}>
        <div
          style={{
            width: "100%",
            height: "75px",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <img src={logo} alt="" />
        </div>
        <div className="topbar">
          <AppBar
            position="static"
            elevation={0}
            style={{
              backgroundColor: "white",
              color: "grey",
              margin: "auto",
            }}
          >
            <AntTabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className="antTabs"
            >
              <AntTab
                style={{ width: 160, height: 75 }}
                label="Dados pessoais"
                disabled={disabledSteps}
                {...a11yProps(0)}
              />
              <AntTab
                label="Localização"
                disabled={disabledSteps}
                {...a11yProps(1)}
              />
              )
              <AntTab
                label="Energias"
                disabled={disabledSteps}
                {...a11yProps(2)}
              />
              <AntTab
                label="Documentos"
                disabled={disabledSteps}
                {...a11yProps(3)}
              />
              <AntTab
                label="Resumo"
                disabled={disabledSteps}
                {...a11yProps(4)}
              />
            </AntTabs>
          </AppBar>
        </div>
      </div>

      <div>
        <TabPanel value={value} index={0} style={{ margin: "4%" }}>
          <DadosPessoais
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            value={value}
            setValue={setValue}
            methods={methods}
            energia={energia}
            resumo={resumo}
            dados={dados}
            setDados={setDados}
            resetFormDados={resetFormDados}
            validationDados={validationDados}
            errorsDados={errorsDados}
          />
        </TabPanel>
        <TabPanel value={value} index={1} style={{ margin: "4%" }}>
          <Localizacao
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            value={value}
            setValue={setValue}
            methods={methods}
            localidade={localidade}
            setLocalidade={setLocalidade}
            resetFormLocalizacao={resetFormLocalizacao}
            validationLocalizacao={validationLocalizacao}
            errorsLocalizacao={errorsLocalizacao}
          />
        </TabPanel>
        <TabPanel value={value} index={2} style={{ margin: "4%" }}>
          <Energias
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            value={value}
            setValue={setValue}
            methods={methods}
            energia={energia}
            setEnergia={setEnergia}
            resetFormEnergia={resetFormEnergia}
            // errors
            validationEnergia={validationEnergia}
            errorsEnergia={errorsEnergia}
            setErrorsEnergia={setErrorsEnergia}
            validEnergias={validEnergias}
            setValidEnergias={setValidEnergias}
            // errors1 - tarifa e potencia
            errorsEnergia1={errorsEnergia1}
            setErrorsEnergia1={setErrorsEnergia1}
            validationEnergia1={validationEnergia1}
            validEnergias1={validEnergias1}
            setValidEnergias1={setValidEnergias1}
            // errors2 - escalao
            errorsEnergia2={errorsEnergia2}
            setErrorsEnergia2={setErrorsEnergia2}
            validationEnergia2={validationEnergia2}
            validEnergias2={validEnergias2}
            setValidEnergias2={setValidEnergias2}
            // errors2 - ciclo
            errorsEnergia3={errorsEnergia3}
            setErrorsEnergia3={setErrorsEnergia3}
            validationEnergia3={validationEnergia3}
            validEnergias3={validEnergias3}
            setValidEnergias3={setValidEnergias3}
          />
        </TabPanel>

        <TabPanel value={value} index={3} style={{ margin: "4%" }}>
          <Documentos
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            value={value}
            setValue={setValue}
            methods={methods}
            documentos={documentos}
            setDocumentos={setDocumentos}
            resetFormDocumentos={resetFormDocumentos}
            validationDocumentos={validationDocumentos}
            errorsDocumentos={errorsDocumentos}
          />
        </TabPanel>

        <TabPanel value={value} index={4} style={{ margin: "4%" }}>
          <Resumo
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            value={value}
            setValue={setValue}
            methods={methods}
            resumo={resumo}
            dados={dados}
            setDados={setDados}
            validationDados={validationDados}
            errorsDados={errorsDados}
            resetFormDados={resetFormDados}
            resetFormLocalizacao={resetFormLocalizacao}
            resetFormEnergia={resetFormEnergia}
            resetFormDocumentos={resetFormDocumentos}
            activeStepResumo={activeStepResumo}
            setActiveStepResumo={setActiveStepResumo}
            setDisabledSteps={setDisabledSteps}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default TopNavGas;
