/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "../css/documentos.css";
import {
  TextField,
  Button,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  ChevronLeft,
  ChevronRight,
  Description,
  Delete,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
});

function getSteps() {
  return ["Documentos"];
}

const CheckboxColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$checked": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DocumentosForm = ({
  documentos,
  setDocumentos,
  validationDocumentos,
  errorsDocumentos,
  validateOnChange = true,
}) => {
  console.log(errorsDocumentos);
  const handleFileChange = (e, tipoDocumento) => {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      var reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      var url = reader.readAsDataURL(file);

      reader.onloadend = () => {
        if (tipoDocumento === "faturaGas") {
          setDocumentos({
            ...documentos,
            faturaGas: {
              imgSrc: [reader.result],
              file: file,
            },
          });
        } else if (tipoDocumento === "comprovativoIban") {
          setDocumentos({
            ...documentos,
            comprovativoIban: {
              imgSrc: [reader.result],
              file: file,
            },
          });
        } else if (tipoDocumento === "outrosDocumentos") {
          setDocumentos({
            ...documentos,
            outrosDocumentos: {
              imgSrc: [reader.result],
              file: file,
            },
          });
        }
      };
    }
    e.target.value = "";
    if (validateOnChange)
      validationDocumentos({ [e.target.name]: e.target.value });
  };

  const handleChangeObervacoes = (e) => {
    setDocumentos({ ...documentos, observacoes: e.target.value });
  };

  const handleChangeAutorizacao = (e) => {
    setDocumentos({
      ...documentos,
      autorizacao: !documentos.autorizacao,
    });
    if (validateOnChange)
      validationDocumentos({ [e.target.name]: !documentos.autorizacao });
  };

  return (
    <>
      <div className="titulo">Carregue aqui os seguintes documentos:</div>
      <ThemeProvider theme={defaultMaterialTheme}>
        {/*---------- Fatura da eletricidade ----------*/}
        <div>
          {Object.values(documentos.faturaGas).length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "7px",
              }}
            >
              <Delete
                onClick={() => setDocumentos({ ...documentos, faturaGas: {} })}
              />
              <p
                style={{
                  marginLeft: "10px",
                }}
              >
                {documentos &&
                  documentos.faturaGas &&
                  documentos.faturaGas.file &&
                  documentos.faturaGas.file.name}
              </p>
            </div>
          ) : (
            <>
              <Button>
                <label htmlFor="faturaGas" className="custom-file-upload">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <Description />
                    <p style={{ margin: "0px 10px", fontSize: "12px" }}>
                      Fatura do Gás *
                    </p>
                  </div>
                </label>
              </Button>
              <input
                type="file"
                name="faturaGas"
                id="faturaGas"
                hidden
                onChange={(e) => handleFileChange(e, "faturaGas")}
                // multiple
                accept=".jpg,.jpeg,.pdf"
              />
              <div>
                {errorsDocumentos.faturaGas && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {errorsDocumentos.faturaGas}
                  </FormHelperText>
                )}
              </div>
            </>
          )}
        </div>
        {/*---------- Comprovativo de IBAN ----------*/}
        <div>
          {Object.values(documentos.comprovativoIban).length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "7px",
              }}
            >
              <Delete
                onClick={() =>
                  setDocumentos({ ...documentos, comprovativoIban: {} })
                }
              />
              <p
                style={{
                  marginLeft: "10px",
                }}
              >
                {documentos &&
                  documentos.comprovativoIban &&
                  documentos.comprovativoIban.file &&
                  documentos.comprovativoIban.file.name}
              </p>
            </div>
          ) : (
            <>
              <Button>
                <label
                  htmlFor="comprovativoIban"
                  className="custom-file-upload"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <Description />
                    <p style={{ margin: "0px 10px", fontSize: "12px" }}>
                      Comprovativo de IBAN *
                    </p>
                  </div>
                </label>
              </Button>
              <input
                type="file"
                name="comprovativoIban"
                id="comprovativoIban"
                hidden
                onChange={(e) => handleFileChange(e, "comprovativoIban")}
                // multiple
                accept=".jpg,.jpeg,.pdf"
              />
              <div>
                {errorsDocumentos.comprovativoIban && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {errorsDocumentos.comprovativoIban}
                  </FormHelperText>
                )}
              </div>
            </>
          )}
        </div>
        {/*---------- Outros Documentos ----------*/}
        <div>
          {Object.values(documentos.outrosDocumentos).length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "7px",
              }}
            >
              <Delete
                onClick={() =>
                  setDocumentos({ ...documentos, outrosDocumentos: {} })
                }
              />
              <p
                style={{
                  marginLeft: "10px",
                }}
              >
                {documentos &&
                  documentos.outrosDocumentos &&
                  documentos.outrosDocumentos.file &&
                  documentos.outrosDocumentos.file.name}
              </p>
            </div>
          ) : (
            <>
              <Button>
                <label
                  htmlFor="outrosDocumentos"
                  className="custom-file-upload"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <Description />
                    <p style={{ margin: "0px 10px", fontSize: "12px" }}>
                      Outros Documentos */**
                    </p>
                  </div>
                </label>
              </Button>
              <input
                type="file"
                name="outrosDocumentos"
                id="outrosDocumentos"
                hidden
                onChange={(e) => handleFileChange(e, "outrosDocumentos")}
                // multiple
                accept=".jpg,.jpeg,.pdf"
              />
            </>
          )}
        </div>
        {/* <span style={{ padding: '10px' }}> */}
        <p style={{ fontSize: "9px", margin: "10px 10px" }}>
          * Os ficheiros apenas podem de ser no formato .pdf e/ou .jpeg
        </p>
        <p style={{ fontSize: "9px", margin: "10px 10px" }}>
          ** Opcional, por exemplo, contratos de arrendamento, escrituras
          públicas, comodatos, procurações,comprovativo de associado, etc.
        </p>
        {/* </span> */}

        {/*---------- Observações ----------*/}
        <div>
          <TextField
            id="observacoes"
            name="observacoes"
            label="Observações"
            className="observacoes"
            value={documentos.observacoes}
            onChange={handleChangeObervacoes}
            multiline
            fullWidth
            autoComplete="off"
            // style={{ maxWidth: '60%' }}
          />
        </div>
        <div>
          <FormGroup
            row
            {...(errorsDocumentos.autorizacao && {
              error: "true",
            })}
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <div>
              <FormControlLabel
                control={
                  <CheckboxColored
                    checked={documentos.autorizacao}
                    onChange={handleChangeAutorizacao}
                    name="autorizacao"
                  />
                }
                label="Autorizo a JAFPlus a aceder aos dados técnicos e comerciais do(s) código(s) universal(ais) de instalação (CUI), que se encontram registados na base de dados do Operador de Redes de Distribuição, com o intuito de me contactar, dentro de alguns dias, com vista à eventual celebração de contrato de fornecimento de energia"
              />
            </div>
            <div>
              {errorsDocumentos.autorizacao && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {errorsDocumentos.autorizacao}
                </FormHelperText>
              )}
            </div>
          </FormGroup>
        </div>
      </ThemeProvider>
    </>
  );
};

function getStepContent(
  step,
  documentos,
  setDocumentos,
  validationDocumentos,
  errorsDocumentos
) {
  switch (step) {
    case 0:
      return (
        <DocumentosForm
          documentos={documentos}
          setDocumentos={setDocumentos}
          validationDocumentos={validationDocumentos}
          errorsDocumentos={errorsDocumentos}
        />
      );

    default:
      return "unknown step";
  }
}

const Documentos = ({
  value,
  methods,
  setValue,
  documentos,
  setDocumentos,
  validationDocumentos,
  errorsDocumentos,
}) => {
  const classes = useStyles();
  console.log(errorsDocumentos);
  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const handleNext = (data) => {
    if (validationDocumentos()) {
      setActiveStep(activeStep + 1);
    } else return null;
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setValue(value - 1);
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      {activeStep === steps.length ? (
        setValue(value + 1)
      ) : (
        <>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            <Button
              className={classes.button}
              onClick={handleBack}
              style={{
                paddingRight: 10,
                borderRadius: "25px",
              }}
            >
              <ChevronLeft />
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                  marginTop: "1px",
                }}
              >
                voltar
              </span>
            </Button>
            <br />
            <br />
            {getStepContent(
              activeStep,
              documentos,
              setDocumentos,
              validationDocumentos,
              errorsDocumentos
            )}
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                paddingRight: 10,
                backgroundColor: "#05b6b7",
                borderRadius: "25px",
                color: "white",
              }}
              size="large"
            >
              Seguinte
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                }}
              >
                <ChevronRight />
              </span>
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default Documentos;
