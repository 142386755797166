import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Simples = ({
  energia,
  setEnergia,
  errorsEnergia1 = null,
  validateOnChange = true,
  validationEnergia1,
  potencias,
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    setEnergia({
      ...energia,
      potencia: e.target.value,
    });
    if (validateOnChange) validationEnergia1({ potencia: e.target.value });
  };

  console.log(potencias);

  return (
    <div>
      <div className="titulo">Selecione a potencia necessária</div>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        {...(errorsEnergia1.potencia && { error: true })}
      >
        <InputLabel id="demo-simple-select-label">Potência</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={energia.potencia}
          onChange={handleChange}
          label="Potência"
          variant="standard"
        >
          {/* Check if potencias is available and has data */}
          {potencias && potencias.length > 0 ? (
            potencias.map((p) => (
              <MenuItem value={`${p.potencia} kVA`} key={p.potencia}>
                {p.potencia} kVA
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              Não existem potências
            </MenuItem>
          )}
        </Select>
        {errorsEnergia1.potencia && (
          <FormHelperText style={{ color: "red" }}>
            {errorsEnergia1.potencia}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default Simples;
