/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "../css/dadosPessoais.css";
import {
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
} from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  withStyles,
  createTheme,
} from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";
import { lightBlue } from "@material-ui/core/colors";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { pt } from "date-fns/locale";

const RadioColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$selected": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: "22%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#05b6b7 !important",
        color: "white !important",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        color: "white !important",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "white !important",
      },
    },
    MuiPickersDay: {
      day: {
        // color: lightBlue.A700,
        // color: '#05b6b7',
      },
      daySelected: {
        // backgroundColor: lightBlue['400'],
        backgroundColor: "#05b6b7",
        color: "white !important",
        "&:hover": {
          background: "#089091 !important",
        },
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        // color: lightBlue['900'],
        color: "#05b6b7",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        // color: lightBlue['400'],
        color: "#05b6b7",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#05b6b7",
      },
    },
  },
});

const CheckboxColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$checked": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function getSteps() {
  return ["Dados Pessoais"];
}
const BasicForm = ({
  dados,
  setDados,
  errorsDados = null,
  validationDados,
  validateOnChange = true,
}) => {
  const handleChange = async (e) => {
    e.preventDefault();
    let texto = e.target.value;

    if (e.target.name === "cod_postal") {
      texto = texto.replace(/\s+/g, "");
      texto = texto.replace(/(\d{4})(?=\d)/g, "$1-");
    }

    if (e.target.name === "cod_postal_cor") {
      texto = texto.replace(/\s+/g, "");
      texto = texto.replace(/(\d{4})(?=\d)/g, "$1-");
    }

    if (e.target.name === "cod_postal" && texto.length === 8) {
      // Perform request after full postal code is entered
      try {
        const response = await fetch(
          `https://jafplus.api.adesao.jaf.com.pt/api/codPostal/${texto}`
        );
        const data = await response.json();

        console.log("API Response: ", data); // Check API response structure

        setDados((prevState) => ({
          ...prevState,
          localidade: data.localidade,
          concelho: data.concelho,
          distrito: data.distrito,
        }));
      } catch (error) {
        console.error("API request failed: ", error);
      }
    }

    if (e.target.name === "cod_postal_cor" && texto.length === 8) {
      // Perform request after full postal code is entered
      try {
        const response = await fetch(
          `https://jafplus.api.adesao.jaf.com.pt/api/codPostal/${texto}`
        );
        const data = await response.json();

        console.log("API Response: ", data); // Check API response structure

        setDados((prevState) => ({
          ...prevState,
          localidade_cor: data.localidade,
          concelho_cor: data.concelho,
          distrito_cor: data.distrito,
        }));
      } catch (error) {
        console.error("API request failed: ", error);
      }
    }

    if (e.target.name === "swift") {
      texto = texto.replace(/\s+/g, "");
    }

    setDados((prevState) => ({
      ...prevState,
      [e.target.name]: texto,
    }));

    if (validateOnChange) validationDados({ [e.target.name]: e.target.value });
  };
  const handleNumbers = (e) => {
    e.preventDefault();

    let value = e.target.value.toString("0000-0000-0000");
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setDados({ ...dados, [e.target.name]: value });
    }

    if (e.target.name === "certidao_permanente") {
      value = value.replace(/(\d{4})(?=\d)/g, "$1-");
      setDados({ ...dados, certidao_permanente: value });
    }

    if (e.target.name === "numero_documento") {
      setDados({ ...dados, numero_documento: value });
    }

    if (validateOnChange) validationDados({ [e.target.name]: value });
  };
  const handleChangeParticularEmpresa = (e) => {
    setDados({
      ...dados,
      checkbox_p_e: e.target.value,
    });
  };
  // const handleChangeTermos = (e) => {
  //   setDados({
  //     ...dados,
  //     aceito_condicoes_gerais: !dados.aceito_condicoes_gerais,
  //   });

  //   if (validateOnChange)
  //     validationDados({ [e.target.name]: !dados.aceito_condicoes_gerais });
  // };
  // const handleChangeTipoDoc = (e) => {
  //   setDados({
  //     ...dados,
  //     tipo_doc: e.target.value,
  //   });
  // };
  const handleChangeClientPrioridade = (e) => {
    setDados({
      ...dados,
      cliente_prioridade: e.target.value,
    });
  };
  const handleChangeTarifaSocial = (e) => {
    setDados({
      ...dados,
      tarifa_social: !dados.tarifa_social,
    });
  };
  const handleChangeEmpNomeInd = (e) => {
    setDados({
      ...dados,
      emp_nome_ind: !dados.emp_nome_ind,
    });
  };
  const handleChangeMesmaMorada = (e) => {
    setDados({
      ...dados,
      mesma_morada: !dados.mesma_morada,
    });
  };
  const handleChangeModalidadePagamento = (e) => {
    setDados({
      ...dados,
      modalidade_pagamento: e.target.value,
    });
  };
  const handleChangeTitularContratoAnterior = (e) => {
    setDados({
      ...dados,
      titular_contrato_anterior: !dados.titular_contrato_anterior,
    });
  };

  const handleChangeValidade = (date) => {
    setDados({
      ...dados,
      validade_documento: date,
    });
    if (validateOnChange) validationDados({ validade_documento: date });
  };

  // const handleChangeCessacaoContrato = (date) => {
  //   setDados({
  //     ...dados,
  //     cessacao_contrato: date,
  //   });
  //   if (validateOnChange) validationDados({ cessacao_contrato: date });
  // };

  const handleChangeContPref = (e) => {
    setDados({
      ...dados,
      preferencia_contacto: e.target.value,
    });
    if (validateOnChange) validationDados({ [e.target.name]: e.target.value });
  };

  const handleChengeEnvioFat = (e) => {
    setDados({
      ...dados,
      envio_fatura: e.target.value,
    });
    if (validateOnChange) validationDados({ [e.target.name]: e.target.value });
  };

  const classes = useStyles();

  return (
    <>
      <div className="titulo">Informações do cliente</div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="checkbox_p_e" // Keep the label reference for accessibility
            name="checkbox_p_e"
            style={{
              display: "flex",
              flexDirection: "row", // Align label and RadioGroup horizontally
              alignItems: "center", // Vertically center label and radio buttons
            }}
          >
            <FormControlLabel
              value="particular"
              control={
                <RadioColored
                  checked={dados.checkbox_p_e === "particular"}
                  onChange={handleChangeParticularEmpresa}
                  name="checkbox_p_e"
                />
              }
              label="Particular"
            />
            <FormControlLabel
              value="empresa"
              control={
                <RadioColored
                  checked={dados.checkbox_p_e === "empresa"}
                  onChange={handleChangeParticularEmpresa}
                  name="checkbox_p_e"
                />
              }
              label="Empresa"
            />
          </RadioGroup>
        </FormControl>
        {dados.checkbox_p_e == "particular" && (
          <div>
            <TextField
              id="Nome"
              name="nome"
              label="Nome *"
              value={dados.nome}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              // className="textfield"
              style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
              {...(errorsDados.nome && {
                error: true,
                helperText: errorsDados.nome,
              })}
            />
            <TextField
              id="Morada"
              name="morada"
              label="Morada *"
              value={dados.morada}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              // className="textfield"
              style={{ marginBottom: 8, width: "48%" }}
              {...(errorsDados.morada && {
                error: true,
                helperText: errorsDados.morada,
              })}
            />
          </div>
        )}
        {dados.checkbox_p_e == "empresa" && (
          <>
            <div>
              <TextField
                id="Nome Empresa"
                name="nome_empresa"
                label="Nome Empresa *"
                value={dados.nome_empresa}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                // className="textfield"
                style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
                {...(errorsDados.nome_empresa && {
                  error: true,
                  helperText: errorsDados.nome_empresa,
                })}
              />
              <TextField
                id="Código da certidão permanente"
                name="cod_certidao_permanente"
                label="Código da certidão permanente *"
                value={dados.cod_certidao_permanente}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                // className="textfield"
                style={{ marginBottom: 8, width: "48%" }}
                {...(errorsDados.cod_certidao_permanente && {
                  error: true,
                  helperText: errorsDados.cod_certidao_permanente,
                })}
              />
            </div>
            <div>
              <TextField
                id="NIPC"
                name="nipc"
                label="NIPC *"
                value={dados.nipc}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                // className="textfield"
                style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
                {...(errorsDados.nipc && {
                  error: true,
                  helperText: errorsDados.nipc,
                })}
              />
              <TextField
                id="CAE"
                name="cae"
                label="CAE *"
                value={dados.cae}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                // className="textfield"
                style={{ marginBottom: 8, width: "48%" }}
                {...(errorsDados.cae && {
                  error: true,
                  helperText: errorsDados.cae,
                })}
              />
            </div>
            <div>
              <TextField
                id="Nome do representante legal"
                name="nome_rep_legal"
                label="Nome do representante legal *"
                value={dados.nome_rep_legal}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                // className="textfield"
                style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
                {...(errorsDados.nome_rep_legal && {
                  error: true,
                  helperText: errorsDados.nome_rep_legal,
                })}
              />
              <TextField
                id="Morada"
                name="morada"
                label="Morada *"
                value={dados.morada}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                // className="textfield"
                style={{ marginBottom: 8, width: "48%" }}
                {...(errorsDados.morada && {
                  error: true,
                  helperText: errorsDados.morada,
                })}
              />
            </div>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            id="cod_postal"
            name="cod_postal"
            label="Código Postal *"
            // className="cod_postal"
            value={dados.cod_postal}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            inputProps={{ maxLength: 8 }}
            style={{ marginBottom: 8, width: "16%" }}
            {...(errorsDados.cod_postal && {
              error: true,
              helperText: errorsDados.cod_postal,
            })}
          />
          <TextField
            id="localidade"
            name="localidade"
            label="Localidade *"
            value={dados.localidade}
            // className="localidade"
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8, width: "28%" }}
            {...(errorsDados.localidade && {
              error: true,
              helperText: errorsDados.localidade,
            })}
          />
          <TextField
            id="concelho"
            name="concelho"
            label="Concelho *"
            value={dados.concelho}
            className="concelho"
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8, width: "22%" }}
            {...(errorsDados.concelho && {
              error: true,
              helperText: errorsDados.concelho,
            })}
          />
          <TextField
            id="distrito"
            name="distrito"
            label="Distrito *"
            value={dados.distrito}
            className="distrito"
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8, width: "22%" }}
            {...(errorsDados.distrito && {
              error: true,
              helperText: errorsDados.distrito,
            })}
          />
        </div>
        {/* <div style={{ display: "flex", marginTop: 20 }}>
          <FormControl>
            <FormLabel
              id="tipo_doc"
              style={{ marginRight: 20 }} // Add space between the label and radio buttons
            >
              Tipo de Documento:
            </FormLabel>

            <RadioGroup
              row
              aria-labelledby="tipo_doc" // Keep the label reference for accessibility
              name="tipo_doc"
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "row", // Align label and RadioGroup horizontally
                alignItems: "center", // Vertically center label and radio buttons
              }}
            >
              <FormControlLabel
                value="1"
                control={
                  <RadioColored
                    checked={dados.tipo_doc === "1"}
                    onChange={handleChangeTipoDoc}
                    name="tipo_doc"
                  />
                }
                label="BI"
              />
              <FormControlLabel
                value="2"
                control={
                  <RadioColored
                    checked={dados.tipo_doc === "2"}
                    onChange={handleChangeTipoDoc}
                    name="tipo_doc"
                  />
                }
                label="CC"
              />
              <FormControlLabel
                value="3"
                control={
                  <RadioColored
                    checked={dados.tipo_doc === "3"}
                    onChange={handleChangeTipoDoc}
                    name="tipo_doc"
                  />
                }
                label="Passaporte"
              />
              <FormControlLabel
                value="4"
                control={
                  <RadioColored
                    checked={dados.tipo_doc === "4"}
                    onChange={handleChangeTipoDoc}
                    name="tipo_doc"
                  />
                }
                label="Outro"
              />
            </RadioGroup>
            <div>
              {dados.tipo_doc && (
                <FormHelperText style={{ color: "#f44336" }}>
                  {errorsDados.tipo_doc}
                </FormHelperText>
              )}
            </div>
          </FormControl>
        </div> */}
        <div>
          <TextField
            id="numero_documento"
            name="numero_documento"
            label="Nº de cartão de cidadão/BI *"
            inputProps={{ maxLength: 15 }}
            value={dados.numero_documento}
            onChange={handleNumbers}
            fullWidth
            autoComplete="off"
            className="numero_documento"
            style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
            {...(errorsDados.numero_documento && {
              error: true,
              helperText: errorsDados.numero_documento,
            })}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
            <KeyboardDatePicker
              style={{ width: "48%", margin: "0px" }}
              className="validade_documento"
              margin="normal"
              id="date-picker-dialog"
              label="Validade do Documento *"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              invalidDateMessage="Data Inválida"
              value={dados?.validade_documento}
              minDate={new Date()}
              minDateMessage={"A Data não pode menor que o valor mínimo (Hoje)"}
              maxDateMessage={
                "A Data escolhida excede a data máxima do calendário"
              }
              onChange={handleChangeValidade}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              {...(errorsDados.validade_documento && {
                error: true,
                helperText: errorsDados.validade_documento,
              })}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <TextField
            id="Nif"
            name="nif"
            label="NIF *"
            inputProps={{ maxLength: 9 }}
            value={dados.nif}
            onChange={handleNumbers}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8, width: "48%" }}
            {...(errorsDados.nif && {
              error: true,
              helperText: errorsDados.nif,
            })}
          />
        </div>
        <div>
          <TextField
            id="telefone"
            name="telefone"
            label="Telefone *"
            inputProps={{ maxLength: 9 }}
            value={dados.telefone}
            onChange={handleNumbers}
            fullWidth
            autoComplete="off"
            className="textfield"
            style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
            {...(errorsDados.telefone && {
              error: true,
              helperText: errorsDados.telefone,
            })}
          />
          <TextField
            id="telemovel"
            name="telemovel"
            label="Telemóvel *"
            inputProps={{ maxLength: 9 }}
            value={dados.telemovel}
            onChange={handleNumbers}
            fullWidth
            autoComplete="off"
            className="textfield"
            style={{ marginBottom: 8, width: "48%" }}
            {...(errorsDados.telemovel && {
              error: true,
              helperText: errorsDados.telemovel,
            })}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            id="Email"
            name="email"
            label="Email *"
            value={dados.email}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8, width: "48%" }}
            {...(errorsDados.email && {
              error: true,
              helperText: errorsDados.email,
            })}
          />
          <FormControl
            className={classes.formControl}
            error={!!errorsDados.preferencia_contacto} // Show error if it exists
          >
            <InputLabel id="preferencia_contacto">
              Contacto Preferencial
            </InputLabel>
            <Select
              labelId="preferencia_contacto"
              id="preferencia_contacto"
              name="preferencia_contacto"
              value={dados.preferencia_contacto}
              onChange={handleChangeContPref}
            >
              <MenuItem value={"email"}>Email</MenuItem>
              <MenuItem value={"contacto_telefonico"}>
                Contacto Telefónico
              </MenuItem>
              <MenuItem value={"correio_postal"}>Correio Postal</MenuItem>
            </Select>

            {/* Conditionally render the helper text if there’s an error */}
            {errorsDados.preferencia_contacto && (
              <FormHelperText>
                {errorsDados.preferencia_contacto}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            className={classes.formControl}
            error={!!errorsDados.envio_fatura}
          >
            <InputLabel id="envio_fatura">Envio de Faturação</InputLabel>
            <Select
              labelId="envio_fatura"
              id="envio_fatura"
              name="envio_fatura"
              value={dados.envio_fatura}
              onChange={handleChengeEnvioFat}
            >
              <MenuItem value={"correio_postal"}>Correio</MenuItem>
              <MenuItem value={"fatura"}>Fatura Eletrónica</MenuItem>
            </Select>
            {/* Conditionally render the helper text if there’s an error */}
            {errorsDados.envio_fatura && (
              <FormHelperText>{errorsDados.envio_fatura}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
            <KeyboardDatePicker
              style={{ width: "48%", marginTop: 0 }}
              // className="cessacao_contrato"
              margin="normal"
              id="date-picker-dialog"
              label="Data de cessação do contrato atual *"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              invalidDateMessage="Data Inválida"
              value={dados?.cessacao_contrato}
              // minDate={new Date()}
              minDateMessage={"A Data não pode menor que o valor mínimo (Hoje)"}
              maxDateMessage={
                "A Data escolhida excede a data máxima do calendário"
              }
              onChange={handleChangeCessacaoContrato}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              {...(errorsDados.cessacao_contrato && {
                error: true,
                helperText: errorsDados.cessacao_contrato,
              })}
            />
          </MuiPickersUtilsProvider> */}
          {dados.envio_fatura == "fatura" && (
            <TextField
              id="email_bill"
              name="email_bill"
              label="Email para fatura *"
              value={dados.email_bill}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              style={{ width: "48%" }}
              {...(errorsDados.email_bill && {
                error: true,
                helperText: errorsDados.email_bill,
              })}
            />
          )}
        </div>
        {/* Cliente prioritário */}
        <div style={{ display: "flex", marginTop: 20 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="cliente_prioridade" // Keep the label reference for accessibility
              name="cliente_prioridade"
              style={{
                display: "flex",
                flexDirection: "row", // Align label and RadioGroup horizontally
                alignItems: "center", // Vertically center label and radio buttons
              }}
            >
              <FormControlLabel
                value="1"
                control={
                  <RadioColored
                    checked={dados.cliente_prioridade === "1"}
                    onChange={handleChangeClientPrioridade}
                    name="cliente_prioridade"
                  />
                }
                label="Cliente prioritário"
              />
              <FormControlLabel
                value="2"
                control={
                  <RadioColored
                    checked={dados.cliente_prioridade === "2"}
                    onChange={handleChangeClientPrioridade}
                    name="cliente_prioridade"
                  />
                }
                label="Cliente com necessidades"
              />
            </RadioGroup>
          </FormControl>
          {/* Tarifa Social */}
          {dados.checkbox_p_e == "particular" ? (
            <FormGroup
              row
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <div>
                {" "}
                <FormControlLabel
                  control={
                    <CheckboxColored
                      checked={dados.tarifa_social}
                      onChange={handleChangeTarifaSocial}
                      name="tarifa_social"
                    />
                  }
                  label="Beneficio de Tarifa Social"
                />
              </div>
            </FormGroup>
          ) : (
            <FormGroup
              row
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <div>
                {" "}
                <FormControlLabel
                  control={
                    <CheckboxColored
                      checked={dados.emp_nome_ind}
                      onChange={handleChangeEmpNomeInd}
                      name="emp_nome_ind"
                    />
                  }
                  label="Empresário em nome individual"
                />
              </div>
            </FormGroup>
          )}
        </div>

        {dados.nif && dados.nif.split("")[0] == 5 && (
          <>
            <div>
              <TextField
                id="certidao_permanente"
                name="certidao_permanente"
                label="Certidão permanente *"
                value={dados.certidao_permanente}
                inputProps={{ maxLength: 14 }}
                onChange={handleNumbers}
                fullWidth
                autoComplete="off"
                style={{ marginBottom: 8 }}
                {...(errorsDados.certidao_permanente && {
                  error: true,
                  helperText: errorsDados.certidao_permanente,
                })}
              />
            </div>
          </>
        )}

        {/* Titular de contrato anterior */}
        <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.titular_contrato_anterior}
                  onChange={handleChangeTitularContratoAnterior}
                  name="titular_contrato_anterior"
                />
              }
              label="O titular é o mesmo do contrato anterior (de outro comercializador)"
            />
          </div>
        </FormGroup>

        {/* Preferência de Contacto */}

        {/* <PreferenciaContacto
          dados={dados}
          setDados={setDados}
          errorsDados={errorsDados}
          validationDados={validationDados}
        /> */}

        {/* Condições Gerais */}
        {/* <FormGroup
          row
          {...(errorsDados.aceito_condicoes_gerais && {
            error: "true",
          })}
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.aceito_condicoes_gerais}
                  onChange={handleChangeTermos}
                  name="aceito_condicoes_gerais"
                />
              }
              label="Aceito os termos e as condições legais de utilização dos meus dados pessoais para fins relacionados exclusivamente à minha adesão."
            />
            <a
              href="https://jafplus.pt/politica-de-privacidade-e-protecao-de-dados-pessoais/"
              target="blank"
            >
              <img
                src={Termos}
                alt=""
                style={{
                  height: "15px",
                  borderRadius: "10px",
                  verticalAlign: "middle",
                }}
              />
            </a>
          </div>

          <div>
            {errorsDados.aceito_condicoes_gerais && (
              <FormHelperText style={{ color: "#f44336" }}>
                {errorsDados.aceito_condicoes_gerais}
              </FormHelperText>
            )}
          </div>
        </FormGroup> */}
        {(dados.preferencia_contacto == "correio_postal" ||
          dados.envio_fatura == "correio_postal") && (
          <>
            <div className="titulo" style={{ marginTop: 10 }}>
              Morada de correspondência
            </div>
            <FormGroup
              row
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <div>
                {" "}
                <FormControlLabel
                  control={
                    <CheckboxColored
                      checked={dados.mesma_morada}
                      onChange={handleChangeMesmaMorada}
                      name="tarifa_social"
                    />
                  }
                  label="Mesma morada"
                />
              </div>
            </FormGroup>
            {dados.mesma_morada == false && (
              <>
                <div>
                  <TextField
                    id="morada_cor"
                    name="morada_cor"
                    label="Morada *"
                    value={dados.morada_cor}
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    // className="textfield"
                    style={{ marginBottom: 8, width: "48%" }}
                    {...(errorsDados.morada_cor && {
                      error: true,
                      helperText: errorsDados.morada_cor,
                    })}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="cod_postal_cor"
                    name="cod_postal_cor"
                    label="Código Postal *"
                    // className="cod_postal"
                    value={dados.cod_postal_cor}
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    inputProps={{ maxLength: 8 }}
                    style={{ marginBottom: 8, width: "14%" }}
                    {...(errorsDados.cod_postal_cor && {
                      error: true,
                      helperText: errorsDados.cod_postal_cor,
                    })}
                  />
                  <TextField
                    id="localidade_cor"
                    name="localidade_cor"
                    label="Localidade *"
                    value={dados.localidade_cor}
                    // className="localidade"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    style={{ marginBottom: 8, width: "30%" }}
                    {...(errorsDados.localidade_cor && {
                      error: true,
                      helperText: errorsDados.localidade_cor,
                    })}
                  />
                  <TextField
                    id="concelho_cor"
                    name="concelho_cor"
                    label="Concelho *"
                    value={dados.concelho_cor}
                    // className="concelho"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    style={{ marginBottom: 8, width: "22%" }}
                    {...(errorsDados.concelho_cor && {
                      error: true,
                      helperText: errorsDados.concelho_cor,
                    })}
                  />
                  <TextField
                    id="distrito_cor"
                    name="distrito_cor"
                    label="Distrito *"
                    value={dados.distrito_cor}
                    // className="distrito"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    style={{ marginBottom: 8, width: "22%" }}
                    {...(errorsDados.distrito_cor && {
                      error: true,
                      helperText: errorsDados.distrito_cor,
                    })}
                  />
                </div>
              </>
            )}
          </>
        )}
        <div className="titulo" style={{ marginTop: 10 }}>
          Modalidade de Pagamento
        </div>
        <FormControl style={{ width: "100%" }}>
          <RadioGroup
            aria-labelledby="modalidade_pagamento" // Keep the label reference for accessibility
            name="modalidade_pagamento"
          >
            <FormControlLabel
              value="1"
              control={
                <RadioColored
                  checked={dados.modalidade_pagamento === "1"}
                  onChange={handleChangeModalidadePagamento}
                  name="modalidade_pagamento"
                />
              }
              label="Multibanco"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 10,
              }}
            >
              <FormControlLabel
                style={{ marginTop: 8, width: "22%" }}
                value="2"
                control={
                  <RadioColored
                    checked={dados.modalidade_pagamento === "2"}
                    onChange={handleChangeModalidadePagamento}
                    name="modalidade_pagamento"
                  />
                }
                label="Débito Direto"
              />
              <TextField
                id="swift"
                name="swift"
                label="SWIFT/BIC"
                value={dados.swift}
                inputProps={{ maxLength: 11 }}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                style={{ marginBottom: 8, width: "22%" }}
                {...(errorsDados.swift && {
                  error: true,
                  helperText: errorsDados.swift,
                })}
              />
              <TextField
                id="iban"
                name="iban"
                label="IBAN"
                value={dados.iban}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                style={{ marginBottom: 8, width: "48%" }}
                inputProps={{
                  maxLength: 21,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ paddingBottom: 2 }}
                    >
                      PT50
                    </InputAdornment>
                  ),
                }}
                {...(errorsDados.iban && {
                  error: true,
                  helperText: errorsDados.iban,
                })}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </ThemeProvider>
    </>
  );
};

function getStepContent(
  step,
  isDisabledSeguinte,
  setIsDisabledSeguinte,
  dados,
  setDados,
  errorsDados,
  resetFormDados,
  validationDados,
  validateOnChange,
  validationDados1
) {
  switch (step) {
    case 0:
      return (
        <BasicForm
          isDisabledSeguinte={isDisabledSeguinte}
          setIsDisabledSeguinte={setIsDisabledSeguinte}
          dados={dados}
          setDados={setDados}
          errorsDados={errorsDados}
          resetFormDados={resetFormDados}
          validationDados={validationDados}
          validateOnChange={validateOnChange}
          validationDados1={validationDados1}
        />
      );
    default:
      return "unknown step";
  }
}

const DadosPessoais = ({
  methods,
  setValue,
  dados,
  setDados,
  resetFormDados,
  errorsDados,
  validationDados,
  errorsDados1,
  validationDados1,
  validateOnChange = true,
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [isDisabledSeguinte, setIsDisabledSeguinte] = useState(true);
  const steps = getSteps();
  const handleNext = (data) => {
    if (validationDados()) {
      if (activeStep === 1 && validationDados1()) {
        setActiveStep(activeStep + 1);
      }
      if (activeStep === 0) {
        setActiveStep(activeStep + 1);
      }
    } else {
      return null;
    }

    // }
  };

  return (
    <div>
      {activeStep === steps.length ? (
        setValue(1)
      ) : (
        <>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            <br />
            <br />
            {getStepContent(
              activeStep,
              isDisabledSeguinte,
              setIsDisabledSeguinte,
              dados,
              setDados,
              errorsDados,
              resetFormDados,
              validationDados,
              validateOnChange,
              errorsDados1,
              validationDados1
            )}

            <br />

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                paddingRight: 10,
                backgroundColor: "#05b6b7",
                borderRadius: "25px",
                color: "white",
              }}
              size="large"
            >
              Seguinte
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                }}
              >
                <ChevronRight />
              </span>
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default DadosPessoais;
